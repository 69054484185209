<template>
  <div class="container" v-loading="loading" element-loading-text="Loading...">
      <el-row :gutter="20">
        <el-col :span="8">
          <el-card shadow="never">
            <template #header>
              <div class="card-header">
                <span>收入</span>
                <el-tag effect="dark">昨日</el-tag>
              </div>
            </template>
            <div class="card-content">
                <div>
                  <h2 v-if="today == '01'">{{ formatMoney(yStatistic.data.amount) || 0 }}<span>元</span></h2>
                  <h2 v-else>{{ formatMoney(thePageData.data.statisticOrders.day_amount) || 0 }}<span>元</span></h2>
                </div>
                <div>
                  <span>总收入</span>
                  <span v-if="today == '01'">{{ yStatistic.data.total }}笔</span>
                  <span v-else>{{ thePageData.data.statisticOrders.day_total }}笔</span>
                </div>
            </div>
          </el-card>
        </el-col>
        <el-col :span="8">
          <el-card shadow="never">   
            <template #header>
              <div class="card-header">
                <span>收入</span>
                <el-tag type="success" effect="dark">本周</el-tag>
              </div>
            </template>
            <div class="card-content">
                <h2>{{ formatMoney(thePageData.data.statisticOrders.week_amount)|| 0 }}<span>元</span></h2>
                <div>
                  <span>总收入</span>
                  <span style="color:#67C23A ;">{{ thePageData.data.statisticOrders.week_total }}笔</span>
                </div>
            </div>
          </el-card>
        </el-col>
        <el-col :span="8">
          <el-card shadow="never"> 
            <template #header>
              <div class="card-header">
                <span>收入</span>
                <el-tag type="danger" effect="dark">本月</el-tag>
              </div>
            </template>
            <div class="card-content">
                <h2>{{ formatMoney(thePageData.data.statisticOrders.month_amount) || 0  }}<span>元</span></h2>
                <div>
                  <span>总收入</span>
                  <span style="color:#F56C6C ;">{{ thePageData.data.statisticOrders.month_total }}笔</span>
                </div>
            </div>
          </el-card>
        </el-col>
      </el-row>
      <!-- <el-row :gutter="20" style="margin-top: 12px;">
        <el-col :span="8">
          <paymentCharts :dynamicProp="thePageData.data" />
        </el-col>
        <el-col :span="8">
           <storeCharts :dynamicProp="thePageData.data" />
        </el-col>
        <el-col :span="8">
           <deviceCharts :dynamicProp="thePageData.data" />
        </el-col>
      </el-row> -->
      <!-- <div class="sales-eachart">
        <sales :dynamicProp="thePageData.data.series"></sales>
      </div> -->
  </div>
</template>

<style lang="less" scoped>
.container {
  width: 100%;
  padding: 12px 0;
  box-sizing: border-box;

  .select-time{
    margin: 12px 0;
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: end;

    .el-date-button{
     margin-left: 20px;
    }
  }

  .card-header{
      display: flex;
      justify-content: space-between;
      align-items: center;
      span{
        font-size: 16px;
        font-weight: bold;
      }
    }

    .card-content{
      box-sizing: border-box;

      h2{
        span{
          font-size: 16px;
          margin-left: 6px;
        }
      }
      div:nth-child(2){
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: 600;
        span{
          font-size: 14px;
          margin-left: 6px;
        }
        span:nth-child(2){
          color: #409EFF;
        }
      }
    }
    .sales-eachart{
      padding: 40px 0;
    }
}


</style>


<script setup>
// import sales from "./sales-echarts.vue"
import { getCurrentInstance,onMounted, reactive ,ref} from "vue";
import {GetYesterdayStartTime,GetYesterdayEndTime,GetTodayEndTime} from "../../tools/dateTools"
onMounted(()=>{getDfata()})
const { proxy } = getCurrentInstance()

const thePageData =reactive({
    data:{
      statisticOrders:{
        day_amount:0,
        day_total:0,
        month_amount:0,
        month_total:0,
        week_amount:0,
        week_total:0
      },
      series:{}
    },
})
const loading= ref(false)


//昨天开始-结束时间
const yesterdayStart = GetYesterdayStartTime()
const yesterdayEnd = GetYesterdayEndTime()
const today = GetTodayEndTime().slice(8,10)
const yStatistic =  reactive({
  data:{
    total:0,
    amount:0
  }
})
// 数据库月份数据分开，每月最后一天单独判断
if(today == '01'){
  proxy.$api.home.getHomeData({created_start:yesterdayStart,created_end:yesterdayEnd}).then(res=>{
    if (res.status == 200 && res.success) {
      console.log(res.data,today);
      yStatistic.data.total =  res.data.statisticOrders.pay_total
      yStatistic.data.amount = res.data.statisticOrders.pay_amount - res.data.statisticOrders.refund_amount
    } 
  })
}

const  getDfata = async ()=>{
  loading.value=true
  await proxy.$api.home.getStatistic().then(res=>{
    if (res.status == 200 && res.success) {
      thePageData.data.series = res.data.series
      thePageData.data.statisticOrders = res.data.statistic
      // console.log(thePageData.data.series);
    }
    loading.value=false
  })
}
const formatMoney = (money)=>{return Number(money).toFixed(2)}
</script>